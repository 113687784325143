export function clearTokens() {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("session_token");
}

export function setTokens(accessToken, refreshToken, sessionToken) {
  if (!accessToken) {
    localStorage.removeItem("access_token");
  } else {
    localStorage.setItem("access_token", accessToken);
  }

  if (!refreshToken) {
    localStorage.removeItem("refresh_token");
  } else {
    localStorage.setItem("refresh_token", refreshToken);
  }

  // not removing session token if not provided - this is also called
  // on refreshing tokens, which do not return session again
  if (sessionToken) {
    localStorage.setItem("session_token", sessionToken);
  }
}
