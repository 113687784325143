let enabled = true;

export function trackEvent(
  eventName: string,
  eventProperties = {},
  withTimeout = true,
) {
  console.info("amplitude :: trackEvent", eventName, eventProperties);
  if (!enabled) {
    return;
  }

  setTimeout(
    () => {
      // @ts-expect-error
      window.EvangelistTracker?.trackEvent({
        name: eventName,
        options: eventProperties,
      });
    },
    withTimeout ? 2500 : 0,
  );
}

export function trackUser(userId: string | undefined) {
  console.info("amplitude :: trackUser", userId ? "user-ok" : "user-ko");
  if (!enabled) {
    return;
  }

  // const { $amplitude } = useNuxtApp();
  // $amplitude.setUserId(userId);

  setTimeout(() => {
    // @ts-expect-error
    window.EvangelistTracker?.identify({ userId: userId });
  }, 2500);
}

export function initializeAmplitude() {
  const publicConfig = useRuntimeConfig().public;
  if (
    publicConfig.IS_DEVELOPMENT &&
    publicConfig.AMPLITUDE_API_KEY === "disabled"
  ) {
    enabled = false;
    console.info("amplitude :: disabled for local development");
    return;
  }

  console.info("amplitude :: initializing");

  if (!publicConfig.AMPLITUDE_API_KEY) {
    console.error("AMPLITUDE_API_KEY is not defined in runtime config");
    return;
  }

  console.info("amplitude :: initialized");

  const { $amplitude } = useNuxtApp();
  $amplitude.init(publicConfig.AMPLITUDE_API_KEY, {
    defaultTracking: { sessions: true },
  });
}
