<script setup lang="ts">
import { getBrandNameForOrigin } from "./advertisorsData/shared/getBrand";
const LazyLanding = defineAsyncComponent(
  () => import("./components/AdvertiserLanding/Landing.vue"),
);

const route = useRoute();
const publicConfig = useRuntimeConfig().public;

const currentDomain = computed(() => {
  return window.location.origin;
});

const brandNameForOrigin = computed(() => {
  const isGuestDomain = publicConfig.GUEST_DOMAINS.includes(
    currentDomain.value,
  );

  if (isGuestDomain) {
    return getBrandNameForOrigin(currentDomain.value);
  }

  return undefined;
});

function getVtidFromRoute() {
  if (typeof route.query?.vtid === "string") {
    return route.query.vtid;
  }
}

function initializeTracker() {
  // @ts-expect-error
  const tracker = window?.EvangelistTrackerComponent;
  if (tracker) {
    const vtid = getVtidFromRoute();
    tracker({ deviceId: vtid }).render("#evangelist-ut");
  }
}

onMounted(() => {
  initializeTracker();
});

useBodyFreeze();
useFixedFullscreen();
</script>
<template>
  <div class="font-semibold">
    <div id="evangelist-ut" class="absolute w-[1px] h-[1px]"></div>
    <LazyLanding v-if="brandNameForOrigin" :brand-name="brandNameForOrigin" />

    <NuxtLayout v-else>
      <NuxtPage />
    </NuxtLayout>
    <CookieBar v-if="brandNameForOrigin" />
  </div>
</template>
