/* tslint:disable */
/* eslint-disable */
/**
 * creatorapp:dev
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: non-prod
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AddWalletDto
 */
export interface AddWalletDto {
    /**
     * 
     * @type {string}
     * @memberof AddWalletDto
     */
    'caipAddress': string;
}
/**
 * 
 * @export
 * @interface AdvertiserDto
 */
export interface AdvertiserDto {
    /**
     * 
     * @type {string}
     * @memberof AdvertiserDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AdvertiserDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AdvertiserDto
     */
    'theme': string;
    /**
     * 
     * @type {boolean}
     * @memberof AdvertiserDto
     */
    'isPreferred': boolean;
}
/**
 * 
 * @export
 * @interface AuthProfileDto
 */
export interface AuthProfileDto {
    /**
     * 
     * @type {string}
     * @memberof AuthProfileDto
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof AuthProfileDto
     */
    'userName': string;
    /**
     * 
     * @type {boolean}
     * @memberof AuthProfileDto
     */
    'verifiedMember': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AuthProfileDto
     */
    'userRoles': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AuthProfileDto
     */
    'dateAccountCreated': string;
    /**
     * 
     * @type {string}
     * @memberof AuthProfileDto
     */
    'twitterHandle': string;
    /**
     * 
     * @type {string}
     * @memberof AuthProfileDto
     */
    'profileImageUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AuthProfileDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof AuthProfileDto
     */
    'verifiedEmail': string;
    /**
     * 
     * @type {boolean}
     * @memberof AuthProfileDto
     */
    'allowEmailNotifications': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AuthProfileDto
     */
    'twoFactorAuthEnabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AuthProfileDto
     */
    'verifiedTwoFactorAuthEnabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof AuthProfileDto
     */
    'cookiePreferences': AuthProfileDtoCookiePreferencesEnum;
    /**
     * 
     * @type {string}
     * @memberof AuthProfileDto
     */
    'tutorialPreferences': AuthProfileDtoTutorialPreferencesEnum;
    /**
     * 
     * @type {boolean}
     * @memberof AuthProfileDto
     */
    'canCreatePost': boolean;
}

export const AuthProfileDtoCookiePreferencesEnum = {
    None: 'none',
    Required: 'required',
    All: 'all'
} as const;

export type AuthProfileDtoCookiePreferencesEnum = typeof AuthProfileDtoCookiePreferencesEnum[keyof typeof AuthProfileDtoCookiePreferencesEnum];
export const AuthProfileDtoTutorialPreferencesEnum = {
    None: 'none',
    Important: 'important',
    All: 'all'
} as const;

export type AuthProfileDtoTutorialPreferencesEnum = typeof AuthProfileDtoTutorialPreferencesEnum[keyof typeof AuthProfileDtoTutorialPreferencesEnum];

/**
 * 
 * @export
 * @interface BalanceDto
 */
export interface BalanceDto {
    /**
     * 
     * @type {Array<BalanceItemDto>}
     * @memberof BalanceDto
     */
    'entries': Array<BalanceItemDto>;
    /**
     * 
     * @type {number}
     * @memberof BalanceDto
     */
    'estimatedTotalValueUsd'?: number;
}
/**
 * 
 * @export
 * @interface BalanceItemDto
 */
export interface BalanceItemDto {
    /**
     * 
     * @type {string}
     * @memberof BalanceItemDto
     */
    'currency': string;
    /**
     * 
     * @type {number}
     * @memberof BalanceItemDto
     */
    'amount': number;
    /**
     * 
     * @type {number}
     * @memberof BalanceItemDto
     */
    'estimatedValueUsd': number | undefined;
    /**
     * 
     * @type {Array<string>}
     * @memberof BalanceItemDto
     */
    'breakdown': Array<string>;
}
/**
 * 
 * @export
 * @interface CallToActionParamDto
 */
export interface CallToActionParamDto {
    /**
     * 
     * @type {string}
     * @memberof CallToActionParamDto
     */
    'campaignId': string;
    /**
     * 
     * @type {string}
     * @memberof CallToActionParamDto
     */
    'externalTransactionUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CallToActionParamDto
     */
    'withdrawalId': string;
    /**
     * 
     * @type {string}
     * @memberof CallToActionParamDto
     */
    'campaignStartDate': string;
    /**
     * 
     * @type {string}
     * @memberof CallToActionParamDto
     */
    'campaignEndDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof CallToActionParamDto
     */
    'hasEligibleCampaign': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CallToActionParamDto
     */
    'hasEligiblePosts': boolean;
    /**
     * 
     * @type {string}
     * @memberof CallToActionParamDto
     */
    'advertiserName': string;
    /**
     * 
     * @type {string}
     * @memberof CallToActionParamDto
     */
    'advertiserTheme': string;
    /**
     * 
     * @type {number}
     * @memberof CallToActionParamDto
     */
    'apyBonus': number;
}
/**
 * 
 * @export
 * @interface CampaignBonusDto
 */
export interface CampaignBonusDto {
    /**
     * 
     * @type {string}
     * @memberof CampaignBonusDto
     */
    'dedupeId': string;
    /**
     * 
     * @type {MonetaryDto}
     * @memberof CampaignBonusDto
     */
    'amount': MonetaryDto;
    /**
     * 
     * @type {string}
     * @memberof CampaignBonusDto
     */
    'state': string;
}
/**
 * 
 * @export
 * @interface CampaignEligibilityDto
 */
export interface CampaignEligibilityDto {
    /**
     * 
     * @type {string}
     * @memberof CampaignEligibilityDto
     */
    'state': CampaignEligibilityDtoStateEnum;
    /**
     * 
     * @type {Array<CampaignRestrictionDto>}
     * @memberof CampaignEligibilityDto
     */
    'restrictions': Array<CampaignRestrictionDto>;
    /**
     * 
     * @type {string}
     * @memberof CampaignEligibilityDto
     */
    'additionalCampaignCheckType': CampaignEligibilityDtoAdditionalCampaignCheckTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CampaignEligibilityDto
     */
    'lastNonEligibleWalletCaipAddress': string;
}

export const CampaignEligibilityDtoStateEnum = {
    New: 'New',
    EvaluationPending: 'EvaluationPending',
    Evaluating: 'Evaluating',
    NotInvited: 'NotInvited',
    NotEligibleBasic: 'NotEligibleBasic',
    EligibleBasic: 'EligibleBasic',
    Eligible: 'Eligible',
    NotEligible: 'NotEligible'
} as const;

export type CampaignEligibilityDtoStateEnum = typeof CampaignEligibilityDtoStateEnum[keyof typeof CampaignEligibilityDtoStateEnum];
export const CampaignEligibilityDtoAdditionalCampaignCheckTypeEnum = {
    None: 'none',
    InvitationOnly: 'invitation-only',
    ExternalCheck: 'external-check',
    ExternalWalletCheck: 'external-wallet-check'
} as const;

export type CampaignEligibilityDtoAdditionalCampaignCheckTypeEnum = typeof CampaignEligibilityDtoAdditionalCampaignCheckTypeEnum[keyof typeof CampaignEligibilityDtoAdditionalCampaignCheckTypeEnum];

/**
 * 
 * @export
 * @interface CampaignPageDto
 */
export interface CampaignPageDto {
    /**
     * 
     * @type {CampaignPageOverviewDto}
     * @memberof CampaignPageDto
     */
    'overview': CampaignPageOverviewDto;
    /**
     * 
     * @type {SuggestedCampaignDto}
     * @memberof CampaignPageDto
     */
    'campaign': SuggestedCampaignDto;
    /**
     * 
     * @type {Array<SuggestedCampaignDto>}
     * @memberof CampaignPageDto
     */
    'otherCampaigns': Array<SuggestedCampaignDto>;
    /**
     * 
     * @type {Array<PostDto>}
     * @memberof CampaignPageDto
     */
    'campaignPosts': Array<PostDto>;
    /**
     * 
     * @type {Array<CampaignTipDto>}
     * @memberof CampaignPageDto
     */
    'campaignTips': Array<CampaignTipDto>;
    /**
     * 
     * @type {LeaderboardDto}
     * @memberof CampaignPageDto
     */
    'leaderboard': LeaderboardDto;
}
/**
 * 
 * @export
 * @interface CampaignPageOverviewDto
 */
export interface CampaignPageOverviewDto {
    /**
     * 
     * @type {number}
     * @memberof CampaignPageOverviewDto
     */
    'numPostsEvaluated': number;
    /**
     * 
     * @type {MonetaryDto}
     * @memberof CampaignPageOverviewDto
     */
    'estimatedReward': MonetaryDto;
}
/**
 * 
 * @export
 * @interface CampaignRestrictionDto
 */
export interface CampaignRestrictionDto {
    /**
     * 
     * @type {string}
     * @memberof CampaignRestrictionDto
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignRestrictionDto
     */
    'contentHtml': string;
}
/**
 * 
 * @export
 * @interface CampaignTipDto
 */
export interface CampaignTipDto {
    /**
     * 
     * @type {string}
     * @memberof CampaignTipDto
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignTipDto
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof CampaignTipDto
     */
    'contentHtml': string;
}
/**
 * 
 * @export
 * @interface CampaignsPageDto
 */
export interface CampaignsPageDto {
    /**
     * 
     * @type {Array<SuggestedCampaignDto>}
     * @memberof CampaignsPageDto
     */
    'campaigns': Array<SuggestedCampaignDto>;
}
/**
 * 
 * @export
 * @interface CompleteWalletVerificationDto
 */
export interface CompleteWalletVerificationDto {
    /**
     * 
     * @type {string}
     * @memberof CompleteWalletVerificationDto
     */
    'verificationId': string;
    /**
     * 
     * @type {string}
     * @memberof CompleteWalletVerificationDto
     */
    'signature': string;
}
/**
 * 
 * @export
 * @interface CreatePostRequestAttachmentDto
 */
export interface CreatePostRequestAttachmentDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePostRequestAttachmentDto
     */
    'dataBase64': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePostRequestAttachmentDto
     */
    'mimeType': string;
}
/**
 * 
 * @export
 * @interface CreatePostRequestDto
 */
export interface CreatePostRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePostRequestDto
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePostRequestDto
     */
    'campaignId'?: string;
    /**
     * 
     * @type {Array<CreatePostRequestAttachmentDto>}
     * @memberof CreatePostRequestDto
     */
    'attachments'?: Array<CreatePostRequestAttachmentDto>;
}
/**
 * 
 * @export
 * @interface CreatePostResponseDto
 */
export interface CreatePostResponseDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePostResponseDto
     */
    'tweetId': string;
}
/**
 * 
 * @export
 * @interface CreateUserRequestDto
 */
export interface CreateUserRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequestDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserRequestDto
     */
    'twitterUserId': string;
    /**
     * 
     * @type {number}
     * @memberof CreateUserRequestDto
     */
    'accessTokenExpirySec': number;
    /**
     * 
     * @type {number}
     * @memberof CreateUserRequestDto
     */
    'refreshTokenExpirySec': number;
}
/**
 * 
 * @export
 * @interface CreateUserResponseDto
 */
export interface CreateUserResponseDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUserResponseDto
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserResponseDto
     */
    'accessToken': string;
    /**
     * 
     * @type {number}
     * @memberof CreateUserResponseDto
     */
    'accessTokenExpirySec': number;
    /**
     * 
     * @type {string}
     * @memberof CreateUserResponseDto
     */
    'refreshToken': string;
    /**
     * 
     * @type {number}
     * @memberof CreateUserResponseDto
     */
    'refreshTokenExpirySec': number;
}
/**
 * 
 * @export
 * @interface DashboardPageDto
 */
export interface DashboardPageDto {
    /**
     * 
     * @type {UserOverviewDto}
     * @memberof DashboardPageDto
     */
    'overview': UserOverviewDto;
    /**
     * 
     * @type {Array<PostDto>}
     * @memberof DashboardPageDto
     */
    'posts': Array<PostDto>;
    /**
     * 
     * @type {Array<SuggestedCampaignDto>}
     * @memberof DashboardPageDto
     */
    'campaigns': Array<SuggestedCampaignDto>;
    /**
     * 
     * @type {UserBalanceDto}
     * @memberof DashboardPageDto
     */
    'balance': UserBalanceDto;
}
/**
 * 
 * @export
 * @interface DashboardPostCampaignDto
 */
export interface DashboardPostCampaignDto {
    /**
     * 
     * @type {AdvertiserDto}
     * @memberof DashboardPostCampaignDto
     */
    'advertiser': AdvertiserDto;
    /**
     * 
     * @type {string}
     * @memberof DashboardPostCampaignDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DashboardPostCampaignDto
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof DashboardPostCampaignDto
     */
    'ratingScore': number;
    /**
     * 
     * @type {number}
     * @memberof DashboardPostCampaignDto
     */
    'rewardAmount': number;
    /**
     * 
     * @type {string}
     * @memberof DashboardPostCampaignDto
     */
    'rewardCurrency': string;
    /**
     * 
     * @type {number}
     * @memberof DashboardPostCampaignDto
     */
    'estimatedValueUsd': number;
}
/**
 * 
 * @export
 * @interface ExternalLoginUrlDto
 */
export interface ExternalLoginUrlDto {
    /**
     * 
     * @type {string}
     * @memberof ExternalLoginUrlDto
     */
    'redirectUrl': string;
    /**
     * 
     * @type {boolean}
     * @memberof ExternalLoginUrlDto
     */
    'isAuthRequired': boolean;
}
/**
 * 
 * @export
 * @interface HomePageDto
 */
export interface HomePageDto {
    /**
     * 
     * @type {Array<SuggestedCampaignDto>}
     * @memberof HomePageDto
     */
    'featuredCampaigns': Array<SuggestedCampaignDto>;
}
/**
 * 
 * @export
 * @interface InvitationCampaignDto
 */
export interface InvitationCampaignDto {
    /**
     * 
     * @type {AdvertiserDto}
     * @memberof InvitationCampaignDto
     */
    'advertiser': AdvertiserDto;
    /**
     * 
     * @type {string}
     * @memberof InvitationCampaignDto
     */
    'dateStart': string;
    /**
     * 
     * @type {string}
     * @memberof InvitationCampaignDto
     */
    'dateEnd': string;
    /**
     * 
     * @type {string}
     * @memberof InvitationCampaignDto
     */
    'budgetCurrency': string;
    /**
     * 
     * @type {MonetaryDto}
     * @memberof InvitationCampaignDto
     */
    'totalBudget': MonetaryDto;
    /**
     * 
     * @type {number}
     * @memberof InvitationCampaignDto
     */
    'apyBonus': number;
    /**
     * 
     * @type {number}
     * @memberof InvitationCampaignDto
     */
    'signupBonus': number;
}
/**
 * 
 * @export
 * @interface InvitationOverviewDto
 */
export interface InvitationOverviewDto {
    /**
     * 
     * @type {string}
     * @memberof InvitationOverviewDto
     */
    'welcomeText': string;
    /**
     * 
     * @type {UserBadgeDto}
     * @memberof InvitationOverviewDto
     */
    'recipient': UserBadgeDto;
    /**
     * 
     * @type {number}
     * @memberof InvitationOverviewDto
     */
    'numPostsEligible': number;
    /**
     * 
     * @type {MonetaryDto}
     * @memberof InvitationOverviewDto
     */
    'availableReward': MonetaryDto;
}
/**
 * 
 * @export
 * @interface InvitationPageDto
 */
export interface InvitationPageDto {
    /**
     * 
     * @type {InvitationOverviewDto}
     * @memberof InvitationPageDto
     */
    'overview': InvitationOverviewDto;
    /**
     * 
     * @type {InvitationCampaignDto}
     * @memberof InvitationPageDto
     */
    'campaign': InvitationCampaignDto;
    /**
     * 
     * @type {Array<InvitationPostDto>}
     * @memberof InvitationPageDto
     */
    'posts': Array<InvitationPostDto>;
}
/**
 * 
 * @export
 * @interface InvitationPostDto
 */
export interface InvitationPostDto {
    /**
     * 
     * @type {string}
     * @memberof InvitationPostDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof InvitationPostDto
     */
    'datePosted': string;
    /**
     * 
     * @type {number}
     * @memberof InvitationPostDto
     */
    'numImpressions': number;
    /**
     * 
     * @type {string}
     * @memberof InvitationPostDto
     */
    'content': string;
}
/**
 * 
 * @export
 * @interface JoinCampaignRequestDto
 */
export interface JoinCampaignRequestDto {
    /**
     * 
     * @type {string}
     * @memberof JoinCampaignRequestDto
     */
    'walletCaipAddress': string;
}
/**
 * 
 * @export
 * @interface JoinCampaignResponseDto
 */
export interface JoinCampaignResponseDto {
    /**
     * 
     * @type {string}
     * @memberof JoinCampaignResponseDto
     */
    'state': string;
}
/**
 * 
 * @export
 * @interface LeaderboardDto
 */
export interface LeaderboardDto {
    /**
     * 
     * @type {Array<LeaderboardEntryDto>}
     * @memberof LeaderboardDto
     */
    'entries': Array<LeaderboardEntryDto>;
    /**
     * 
     * @type {MonetaryDto}
     * @memberof LeaderboardDto
     */
    'estimatedImpactShare': MonetaryDto;
}
/**
 * 
 * @export
 * @interface LeaderboardEntryDto
 */
export interface LeaderboardEntryDto {
    /**
     * 
     * @type {number}
     * @memberof LeaderboardEntryDto
     */
    'rank': number;
    /**
     * 
     * @type {string}
     * @memberof LeaderboardEntryDto
     */
    'userProfilePictureUrl': string;
    /**
     * 
     * @type {string}
     * @memberof LeaderboardEntryDto
     */
    'userTwitterHandle': string;
    /**
     * 
     * @type {number}
     * @memberof LeaderboardEntryDto
     */
    'relativeImpactPercent': number;
    /**
     * 
     * @type {boolean}
     * @memberof LeaderboardEntryDto
     */
    'isLoggedUser': boolean;
}
/**
 * 
 * @export
 * @interface MonetaryDto
 */
export interface MonetaryDto {
    /**
     * 
     * @type {number}
     * @memberof MonetaryDto
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof MonetaryDto
     */
    'currency': string;
    /**
     * 
     * @type {number}
     * @memberof MonetaryDto
     */
    'estimatedValueUsd'?: number;
}
/**
 * 
 * @export
 * @interface NotificationDto
 */
export interface NotificationDto {
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'dateCreated': string;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationDto
     */
    'isRead': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationDto
     */
    'isSent': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationDto
     */
    'isCallToActionAvailable': boolean;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'eventType': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    'eventText': string;
    /**
     * 
     * @type {CallToActionParamDto}
     * @memberof NotificationDto
     */
    'callToActionParam': CallToActionParamDto;
}
/**
 * 
 * @export
 * @interface PayoutCampaignDto
 */
export interface PayoutCampaignDto {
    /**
     * 
     * @type {string}
     * @memberof PayoutCampaignDto
     */
    'id': string;
    /**
     * 
     * @type {AdvertiserDto}
     * @memberof PayoutCampaignDto
     */
    'advertiser': AdvertiserDto;
    /**
     * 
     * @type {string}
     * @memberof PayoutCampaignDto
     */
    'title': string;
    /**
     * 
     * @type {number}
     * @memberof PayoutCampaignDto
     */
    'apyBonus': number;
}
/**
 * 
 * @export
 * @interface PayoutDto
 */
export interface PayoutDto {
    /**
     * 
     * @type {PayoutCampaignDto}
     * @memberof PayoutDto
     */
    'campaign': PayoutCampaignDto;
    /**
     * 
     * @type {PayoutRewardDto}
     * @memberof PayoutDto
     */
    'reward': PayoutRewardDto;
    /**
     * 
     * @type {Array<PayoutPostDto>}
     * @memberof PayoutDto
     */
    'posts': Array<PayoutPostDto>;
    /**
     * 
     * @type {LeaderboardDto}
     * @memberof PayoutDto
     */
    'leaderboard': LeaderboardDto;
}
/**
 * 
 * @export
 * @interface PayoutPostDto
 */
export interface PayoutPostDto {
    /**
     * 
     * @type {string}
     * @memberof PayoutPostDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PayoutPostDto
     */
    'datePosted': string;
    /**
     * 
     * @type {string}
     * @memberof PayoutPostDto
     */
    'dateExpires': string;
    /**
     * 
     * @type {string}
     * @memberof PayoutPostDto
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof PayoutPostDto
     */
    'origin': string;
    /**
     * 
     * @type {MonetaryDto}
     * @memberof PayoutPostDto
     */
    'reward': MonetaryDto;
    /**
     * 
     * @type {number}
     * @memberof PayoutPostDto
     */
    'score': number;
}
/**
 * 
 * @export
 * @interface PayoutRewardDto
 */
export interface PayoutRewardDto {
    /**
     * 
     * @type {number}
     * @memberof PayoutRewardDto
     */
    'averagePostScore': number;
    /**
     * 
     * @type {MonetaryDto}
     * @memberof PayoutRewardDto
     */
    'totalRewards': MonetaryDto;
}
/**
 * 
 * @export
 * @interface PostDetailDto
 */
export interface PostDetailDto {
    /**
     * 
     * @type {string}
     * @memberof PostDetailDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PostDetailDto
     */
    'datePosted': string;
    /**
     * 
     * @type {string}
     * @memberof PostDetailDto
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof PostDetailDto
     */
    'dateExpires': string;
    /**
     * 
     * @type {string}
     * @memberof PostDetailDto
     */
    'authorTwitterId': string;
    /**
     * 
     * @type {string}
     * @memberof PostDetailDto
     */
    'authorTwitterHandle': string;
    /**
     * 
     * @type {string}
     * @memberof PostDetailDto
     */
    'authorProfilePicUrl': string;
    /**
     * 
     * @type {string}
     * @memberof PostDetailDto
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof PostDetailDto
     */
    'origin': string;
    /**
     * 
     * @type {number}
     * @memberof PostDetailDto
     */
    'numImpressions': number;
    /**
     * 
     * @type {number}
     * @memberof PostDetailDto
     */
    'numReplies': number;
    /**
     * 
     * @type {number}
     * @memberof PostDetailDto
     */
    'numLikes': number;
    /**
     * 
     * @type {number}
     * @memberof PostDetailDto
     */
    'numQuotes': number;
    /**
     * 
     * @type {number}
     * @memberof PostDetailDto
     */
    'numRetweets': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostDetailDto
     */
    'previewImageUrls': Array<string>;
    /**
     * 
     * @type {PostRatingDto}
     * @memberof PostDetailDto
     */
    'rating': PostRatingDto;
    /**
     * 
     * @type {Array<SuggestedCampaignDto>}
     * @memberof PostDetailDto
     */
    'campaigns': Array<SuggestedCampaignDto>;
}
/**
 * 
 * @export
 * @interface PostDto
 */
export interface PostDto {
    /**
     * 
     * @type {string}
     * @memberof PostDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PostDto
     */
    'datePosted': string;
    /**
     * 
     * @type {string}
     * @memberof PostDto
     */
    'dateExpires': string;
    /**
     * 
     * @type {string}
     * @memberof PostDto
     */
    'content': string;
    /**
     * 
     * @type {DashboardPostCampaignDto}
     * @memberof PostDto
     */
    'selectedCampaign': DashboardPostCampaignDto;
    /**
     * 
     * @type {Array<DashboardPostCampaignDto>}
     * @memberof PostDto
     */
    'campaigns': Array<DashboardPostCampaignDto>;
    /**
     * 
     * @type {AdvertiserDto}
     * @memberof PostDto
     */
    'advertiser': AdvertiserDto;
    /**
     * 
     * @type {string}
     * @memberof PostDto
     */
    'origin': string;
    /**
     * 
     * @type {string}
     * @memberof PostDto
     */
    'state': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostDto
     */
    'previewImageUrls': Array<string>;
}
/**
 * 
 * @export
 * @interface PostRatingDto
 */
export interface PostRatingDto {
    /**
     * 
     * @type {string}
     * @memberof PostRatingDto
     */
    'state': PostRatingDtoStateEnum;
    /**
     * 
     * @type {MonetaryDto}
     * @memberof PostRatingDto
     */
    'reward': MonetaryDto;
    /**
     * 
     * @type {number}
     * @memberof PostRatingDto
     */
    'ratingScore': number;
}

export const PostRatingDtoStateEnum = {
    Evaluating: 'evaluating',
    Eligible: 'eligible',
    EligibleBonus: 'eligible-bonus',
    NotEligible: 'not-eligible',
    Expired: 'expired',
    ExpiredBonus: 'expired-bonus',
    Claimed: 'claimed',
    Rewarded: 'rewarded',
    NotRewarded: 'not-rewarded',
    Paid: 'paid',
    PaidBonus: 'paid-bonus',
    Deleted: 'deleted'
} as const;

export type PostRatingDtoStateEnum = typeof PostRatingDtoStateEnum[keyof typeof PostRatingDtoStateEnum];

/**
 * 
 * @export
 * @interface ProfilePageDto
 */
export interface ProfilePageDto {
    /**
     * 
     * @type {UserOverviewDto}
     * @memberof ProfilePageDto
     */
    'overview': UserOverviewDto;
    /**
     * 
     * @type {Array<PostDto>}
     * @memberof ProfilePageDto
     */
    'posts': Array<PostDto>;
    /**
     * 
     * @type {Array<SuggestedCampaignDto>}
     * @memberof ProfilePageDto
     */
    'campaigns': Array<SuggestedCampaignDto>;
    /**
     * 
     * @type {UserBalanceDto}
     * @memberof ProfilePageDto
     */
    'balance': UserBalanceDto;
}
/**
 * 
 * @export
 * @interface RefreshTokenRequestDto
 */
export interface RefreshTokenRequestDto {
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenRequestDto
     */
    'refreshToken': string;
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenRequestDto
     */
    'accessToken': string;
}
/**
 * 
 * @export
 * @interface RefreshTokenResponseDto
 */
export interface RefreshTokenResponseDto {
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenResponseDto
     */
    'accessToken': string;
    /**
     * 
     * @type {number}
     * @memberof RefreshTokenResponseDto
     */
    'accessTokenExpirySeconds': number;
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenResponseDto
     */
    'refreshToken': string;
    /**
     * 
     * @type {number}
     * @memberof RefreshTokenResponseDto
     */
    'refreshTokenExpirySeconds': number;
}
/**
 * 
 * @export
 * @interface StartSpoofingDto
 */
export interface StartSpoofingDto {
    /**
     * 
     * @type {string}
     * @memberof StartSpoofingDto
     */
    'spoofedUserId': string;
}
/**
 * 
 * @export
 * @interface StartWalletVerificationDto
 */
export interface StartWalletVerificationDto {
    /**
     * Text that will be signed by the user          Must contain literal string \"__TOKEN__\" (without quotes)     which will be replaced by the actual token value
     * @type {string}
     * @memberof StartWalletVerificationDto
     */
    'textToSignTemplate': string;
    /**
     * 
     * @type {string}
     * @memberof StartWalletVerificationDto
     */
    'walletId': string;
}
/**
 * 
 * @export
 * @interface StartWalletVerificationResponseDto
 */
export interface StartWalletVerificationResponseDto {
    /**
     * 
     * @type {string}
     * @memberof StartWalletVerificationResponseDto
     */
    'verificationId': string;
    /**
     * 
     * @type {string}
     * @memberof StartWalletVerificationResponseDto
     */
    'fullTextToSign': string;
}
/**
 * 
 * @export
 * @interface SuggestedCampaignDto
 */
export interface SuggestedCampaignDto {
    /**
     * 
     * @type {string}
     * @memberof SuggestedCampaignDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SuggestedCampaignDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof SuggestedCampaignDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof SuggestedCampaignDto
     */
    'descriptionHtml': string;
    /**
     * 
     * @type {string}
     * @memberof SuggestedCampaignDto
     */
    'budgetCurrency': string;
    /**
     * 
     * @type {MonetaryDto}
     * @memberof SuggestedCampaignDto
     */
    'totalBudget': MonetaryDto;
    /**
     * 
     * @type {number}
     * @memberof SuggestedCampaignDto
     */
    'apyBonus': number;
    /**
     * 
     * @type {number}
     * @memberof SuggestedCampaignDto
     */
    'signupBonus': number;
    /**
     * 
     * @type {AdvertiserDto}
     * @memberof SuggestedCampaignDto
     */
    'advertiser': AdvertiserDto;
    /**
     * 
     * @type {string}
     * @memberof SuggestedCampaignDto
     */
    'walletAddress': string;
    /**
     * 
     * @type {string}
     * @memberof SuggestedCampaignDto
     */
    'state': SuggestedCampaignDtoStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof SuggestedCampaignDto
     */
    'participated': boolean;
    /**
     * 
     * @type {CampaignEligibilityDto}
     * @memberof SuggestedCampaignDto
     */
    'eligibility': CampaignEligibilityDto;
    /**
     * 
     * @type {Array<CampaignBonusDto>}
     * @memberof SuggestedCampaignDto
     */
    'bonuses': Array<CampaignBonusDto>;
    /**
     * 
     * @type {string}
     * @memberof SuggestedCampaignDto
     */
    'dateStart': string;
    /**
     * 
     * @type {string}
     * @memberof SuggestedCampaignDto
     */
    'dateEnd': string;
    /**
     * 
     * @type {string}
     * @memberof SuggestedCampaignDto
     */
    'dateMaturity': string;
}

export const SuggestedCampaignDtoStateEnum = {
    Inactive: 'inactive',
    Active: 'active',
    Ending: 'ending',
    Evaluating: 'evaluating',
    Evaluated: 'evaluated'
} as const;

export type SuggestedCampaignDtoStateEnum = typeof SuggestedCampaignDtoStateEnum[keyof typeof SuggestedCampaignDtoStateEnum];

/**
 * 
 * @export
 * @interface TwoFactorVerificationRequest
 */
export interface TwoFactorVerificationRequest {
    /**
     * 
     * @type {string}
     * @memberof TwoFactorVerificationRequest
     */
    'otp': string;
}
/**
 * 
 * @export
 * @interface UpdateUserBasicSettingsDto
 */
export interface UpdateUserBasicSettingsDto {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserBasicSettingsDto
     */
    'allowEmailNotifications': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserBasicSettingsDto
     */
    'cookiePreferences': UpdateUserBasicSettingsDtoCookiePreferencesEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserBasicSettingsDto
     */
    'tutorialPreferences': UpdateUserBasicSettingsDtoTutorialPreferencesEnum;
}

export const UpdateUserBasicSettingsDtoCookiePreferencesEnum = {
    None: 'none',
    Required: 'required',
    All: 'all'
} as const;

export type UpdateUserBasicSettingsDtoCookiePreferencesEnum = typeof UpdateUserBasicSettingsDtoCookiePreferencesEnum[keyof typeof UpdateUserBasicSettingsDtoCookiePreferencesEnum];
export const UpdateUserBasicSettingsDtoTutorialPreferencesEnum = {
    None: 'none',
    Important: 'important',
    All: 'all'
} as const;

export type UpdateUserBasicSettingsDtoTutorialPreferencesEnum = typeof UpdateUserBasicSettingsDtoTutorialPreferencesEnum[keyof typeof UpdateUserBasicSettingsDtoTutorialPreferencesEnum];

/**
 * 
 * @export
 * @interface UpdateUserEmailDto
 */
export interface UpdateUserEmailDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserEmailDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface UpdateUserTwoFactorEnabledDto
 */
export interface UpdateUserTwoFactorEnabledDto {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserTwoFactorEnabledDto
     */
    'enabled': boolean;
}
/**
 * 
 * @export
 * @interface UserBadgeDto
 */
export interface UserBadgeDto {
    /**
     * 
     * @type {string}
     * @memberof UserBadgeDto
     */
    'twitterHandle': string;
    /**
     * 
     * @type {string}
     * @memberof UserBadgeDto
     */
    'profilePicUrl': string;
}
/**
 * 
 * @export
 * @interface UserBalanceDto
 */
export interface UserBalanceDto {
    /**
     * 
     * @type {Array<UserBalanceItemDto>}
     * @memberof UserBalanceDto
     */
    'entries': Array<UserBalanceItemDto>;
    /**
     * 
     * @type {number}
     * @memberof UserBalanceDto
     */
    'estimatedTotalValueUsd'?: number;
}
/**
 * 
 * @export
 * @interface UserBalanceItemDto
 */
export interface UserBalanceItemDto {
    /**
     * 
     * @type {string}
     * @memberof UserBalanceItemDto
     */
    'currency': string;
    /**
     * 
     * @type {number}
     * @memberof UserBalanceItemDto
     */
    'amount': number;
    /**
     * 
     * @type {object}
     * @memberof UserBalanceItemDto
     */
    'estimatedValueUsd': object;
}
/**
 * 
 * @export
 * @interface UserOverviewDto
 */
export interface UserOverviewDto {
    /**
     * 
     * @type {UserBadgeDto}
     * @memberof UserOverviewDto
     */
    'badge': UserBadgeDto;
    /**
     * 
     * @type {number}
     * @memberof UserOverviewDto
     */
    'numPostsEvaluating': number;
    /**
     * 
     * @type {number}
     * @memberof UserOverviewDto
     */
    'numPostsEligible': number;
    /**
     * 
     * @type {number}
     * @memberof UserOverviewDto
     */
    'numPostsEvaluatedTotal': number;
    /**
     * 
     * @type {number}
     * @memberof UserOverviewDto
     */
    'numCampaignsParticipatedTotal': number;
    /**
     * 
     * @type {MonetaryDto}
     * @memberof UserOverviewDto
     */
    'estimatedUnpaidReward': MonetaryDto;
    /**
     * 
     * @type {MonetaryDto}
     * @memberof UserOverviewDto
     */
    'estimatedRewardTotal': MonetaryDto;
}
/**
 * 
 * @export
 * @interface UserSettingsDto
 */
export interface UserSettingsDto {
    /**
     * 
     * @type {boolean}
     * @memberof UserSettingsDto
     */
    'allowEmailNotifications': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserSettingsDto
     */
    'twoFactorAuthEnabled': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserSettingsDto
     */
    'verifiedTwoFactorAuthEnabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof UserSettingsDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserSettingsDto
     */
    'verifiedEmail': string;
    /**
     * 
     * @type {string}
     * @memberof UserSettingsDto
     */
    'cookiePreferences': string;
    /**
     * 
     * @type {string}
     * @memberof UserSettingsDto
     */
    'tutorialPreferences': string;
}
/**
 * 
 * @export
 * @interface WalletCreatedDto
 */
export interface WalletCreatedDto {
    /**
     * 
     * @type {string}
     * @memberof WalletCreatedDto
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof WalletCreatedDto
     */
    'reused': boolean;
}
/**
 * 
 * @export
 * @interface WalletDto
 */
export interface WalletDto {
    /**
     * 
     * @type {string}
     * @memberof WalletDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WalletDto
     */
    'typeName': string;
    /**
     * 
     * @type {string}
     * @memberof WalletDto
     */
    'typeId': string;
    /**
     * 
     * @type {string}
     * @memberof WalletDto
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof WalletDto
     */
    'caipAddress': string;
    /**
     * 
     * @type {string}
     * @memberof WalletDto
     */
    'state': WalletDtoStateEnum;
}

export const WalletDtoStateEnum = {
    New: 'new',
    Approved: 'approved',
    Verified: 'verified'
} as const;

export type WalletDtoStateEnum = typeof WalletDtoStateEnum[keyof typeof WalletDtoStateEnum];

/**
 * 
 * @export
 * @interface WalletEventDto
 */
export interface WalletEventDto {
    /**
     * 
     * @type {string}
     * @memberof WalletEventDto
     */
    'eventDate': string;
    /**
     * 
     * @type {string}
     * @memberof WalletEventDto
     */
    'eventType': string;
    /**
     * 
     * @type {string}
     * @memberof WalletEventDto
     */
    'eventSource': string;
    /**
     * 
     * @type {string}
     * @memberof WalletEventDto
     */
    'walletId': string;
    /**
     * 
     * @type {object}
     * @memberof WalletEventDto
     */
    'metadata': object;
}
/**
 * 
 * @export
 * @interface WalletTypeDto
 */
export interface WalletTypeDto {
    /**
     * 
     * @type {string}
     * @memberof WalletTypeDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WalletTypeDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof WalletTypeDto
     */
    'chainId': string;
    /**
     * 
     * @type {string}
     * @memberof WalletTypeDto
     */
    'addressRegexp': string;
    /**
     * 
     * @type {number}
     * @memberof WalletTypeDto
     */
    'estimatedFeeRate': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof WalletTypeDto
     */
    'currencies': Array<string>;
}
/**
 * 
 * @export
 * @interface WithdrawRequestDto
 */
export interface WithdrawRequestDto {
    /**
     * 
     * @type {string}
     * @memberof WithdrawRequestDto
     */
    'currency': string;
    /**
     * 
     * @type {number}
     * @memberof WithdrawRequestDto
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof WithdrawRequestDto
     */
    'targetWalletId': string;
}
/**
 * 
 * @export
 * @interface WithdrawalDto
 */
export interface WithdrawalDto {
    /**
     * 
     * @type {string}
     * @memberof WithdrawalDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalDto
     */
    'state': WithdrawalDtoStateEnum;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalDto
     */
    'targetWalletAddress': string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalDto
     */
    'targetWalletName': string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalDto
     */
    'currency': string;
    /**
     * 
     * @type {number}
     * @memberof WithdrawalDto
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalDto
     */
    'dateSettled': string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalDto
     */
    'settledByTransactionId': string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalDto
     */
    'externalTransactionUrl': string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalDto
     */
    'externalWalletUrl': string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalDto
     */
    'reason': string | null;
}

export const WithdrawalDtoStateEnum = {
    Verifying: 'verifying',
    Pending: 'pending',
    Processing: 'processing',
    Cancelled: 'cancelled',
    Completed: 'completed',
    Failed: 'failed'
} as const;

export type WithdrawalDtoStateEnum = typeof WithdrawalDtoStateEnum[keyof typeof WithdrawalDtoStateEnum];


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionControllerClaimPost: async (postId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('actionControllerClaimPost', 'postId', postId)
            const localVarPath = `/v1/action/post/claim/{postId}`
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionControllerConfirmPayout: async (campaignId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('actionControllerConfirmPayout', 'campaignId', campaignId)
            const localVarPath = `/v1/action/payout/confirm/{campaignId}`
                .replace(`{${"campaignId"}}`, encodeURIComponent(String(campaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreatePostRequestDto} createPostRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionControllerCreatePost: async (createPostRequestDto: CreatePostRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createPostRequestDto' is not null or undefined
            assertParamExists('actionControllerCreatePost', 'createPostRequestDto', createPostRequestDto)
            const localVarPath = `/v1/action/post/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPostRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionControllerHidePost: async (postId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('actionControllerHidePost', 'postId', postId)
            const localVarPath = `/v1/action/post/hide/{postId}`
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateUserRequestDto} createUserRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerCreateUser: async (createUserRequestDto: CreateUserRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserRequestDto' is not null or undefined
            assertParamExists('authControllerCreateUser', 'createUserRequestDto', createUserRequestDto)
            const localVarPath = `/auth/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerGetProfile: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RefreshTokenRequestDto} refreshTokenRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRefreshToken: async (refreshTokenRequestDto: RefreshTokenRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshTokenRequestDto' is not null or undefined
            assertParamExists('authControllerRefreshToken', 'refreshTokenRequestDto', refreshTokenRequestDto)
            const localVarPath = `/auth/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refreshTokenRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [afterLoginRedirectUrl] 
         * @param {string} [onCancelLoginRedirectUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerTwitterAdvertiserLogin: async (afterLoginRedirectUrl?: string, onCancelLoginRedirectUrl?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/twitter/advertiser/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (afterLoginRedirectUrl !== undefined) {
                localVarQueryParameter['afterLoginRedirectUrl'] = afterLoginRedirectUrl;
            }

            if (onCancelLoginRedirectUrl !== undefined) {
                localVarQueryParameter['onCancelLoginRedirectUrl'] = onCancelLoginRedirectUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} state 
         * @param {string} [code] 
         * @param {string} [error] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerTwitterCallback: async (state: string, code?: string, error?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'state' is not null or undefined
            assertParamExists('authControllerTwitterCallback', 'state', state)
            const localVarPath = `/auth/twitter/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (error !== undefined) {
                localVarQueryParameter['error'] = error;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [afterLoginRedirectUrl] 
         * @param {string} [onCancelLoginRedirectUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerTwitterReadLogin: async (afterLoginRedirectUrl?: string, onCancelLoginRedirectUrl?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/twitter/read/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (afterLoginRedirectUrl !== undefined) {
                localVarQueryParameter['afterLoginRedirectUrl'] = afterLoginRedirectUrl;
            }

            if (onCancelLoginRedirectUrl !== undefined) {
                localVarQueryParameter['onCancelLoginRedirectUrl'] = onCancelLoginRedirectUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [afterLoginRedirectUrl] 
         * @param {string} [onCancelLoginRedirectUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerTwitterWriteLogin: async (afterLoginRedirectUrl?: string, onCancelLoginRedirectUrl?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/twitter/write/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (afterLoginRedirectUrl !== undefined) {
                localVarQueryParameter['afterLoginRedirectUrl'] = afterLoginRedirectUrl;
            }

            if (onCancelLoginRedirectUrl !== undefined) {
                localVarQueryParameter['onCancelLoginRedirectUrl'] = onCancelLoginRedirectUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        balanceControllerGetBalance: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/balance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignPageControllerGetCampaignDetail: async (campaignId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('campaignPageControllerGetCampaignDetail', 'campaignId', campaignId)
            const localVarPath = `/v1/page/campaign/{campaignId}`
                .replace(`{${"campaignId"}}`, encodeURIComponent(String(campaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignPageControllerGetCampaigns: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/page/campaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignPageControllerGetPastCampaigns: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/page/past-campaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignWalletsControllerGetCampaignWallets: async (campaignId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('campaignWalletsControllerGetCampaignWallets', 'campaignId', campaignId)
            const localVarPath = `/v1/campaigns/{campaignId}/wallets`
                .replace(`{${"campaignId"}}`, encodeURIComponent(String(campaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WalletEventDto} walletEventDto 
         * @param {string} [xApiSecret] 
         * @param {string} [xApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversionControllerIngestEvent: async (walletEventDto: WalletEventDto, xApiSecret?: string, xApiKey?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletEventDto' is not null or undefined
            assertParamExists('conversionControllerIngestEvent', 'walletEventDto', walletEventDto)
            const localVarPath = `/v1/conversion/event/ingest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xApiSecret != null) {
                localVarHeaderParameter['x-api-secret'] = String(xApiSecret);
            }

            if (xApiKey != null) {
                localVarHeaderParameter['x-api-key'] = String(xApiKey);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(walletEventDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardPageControllerGetDashboardPage: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/page/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} advertiserName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        homePageControllerGetCampaignsByAdvertiserName: async (advertiserName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'advertiserName' is not null or undefined
            assertParamExists('homePageControllerGetCampaignsByAdvertiserName', 'advertiserName', advertiserName)
            const localVarPath = `/v1/page/home/advertiserCampaigns/{advertiserName}`
                .replace(`{${"advertiserName"}}`, encodeURIComponent(String(advertiserName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} publicToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationPageControllerGetInvitationPage: async (publicToken: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicToken' is not null or undefined
            assertParamExists('invitationPageControllerGetInvitationPage', 'publicToken', publicToken)
            const localVarPath = `/v1/page/invitation/token/{publicToken}`
                .replace(`{${"publicToken"}}`, encodeURIComponent(String(publicToken)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {JoinCampaignRequestDto} joinCampaignRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinCampaignControllerJoinCampaign: async (campaignId: string, joinCampaignRequestDto: JoinCampaignRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('joinCampaignControllerJoinCampaign', 'campaignId', campaignId)
            // verify required parameter 'joinCampaignRequestDto' is not null or undefined
            assertParamExists('joinCampaignControllerJoinCampaign', 'joinCampaignRequestDto', joinCampaignRequestDto)
            const localVarPath = `/v1/page/campaign/{campaignId}/join`
                .replace(`{${"campaignId"}}`, encodeURIComponent(String(campaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(joinCampaignRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerGetNotifications: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/notification/recent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerMarkAllNotificationsRead: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerMarkNotificationRead: async (notificationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationId' is not null or undefined
            assertParamExists('notificationControllerMarkNotificationRead', 'notificationId', notificationId)
            const localVarPath = `/v1/notification/{notificationId}`
                .replace(`{${"notificationId"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payoutControllerGetPendingPayouts: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/payout/unpaid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingControllerPing: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDetailControllerGetPostDetail: async (postId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('postDetailControllerGetPostDetail', 'postId', postId)
            const localVarPath = `/v1/post-detail/{postId}`
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} postId 
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDetailControllerGetPostDetailWithinCampaign: async (postId: string, campaignId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('postDetailControllerGetPostDetailWithinCampaign', 'postId', postId)
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('postDetailControllerGetPostDetailWithinCampaign', 'campaignId', campaignId)
            const localVarPath = `/v1/post-detail/campaign/{campaignId}/{postId}`
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)))
                .replace(`{${"campaignId"}}`, encodeURIComponent(String(campaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profilePageControllerGetProfilePage: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/page/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StartSpoofingDto} startSpoofingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spoofingControllerStartSpoofing: async (startSpoofingDto: StartSpoofingDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startSpoofingDto' is not null or undefined
            assertParamExists('spoofingControllerStartSpoofing', 'startSpoofingDto', startSpoofingDto)
            const localVarPath = `/auth/spoofing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startSpoofingDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spoofingControllerStopSpoofing: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/spoofing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSettingsBasicControllerGetUserSettings: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/user/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateUserBasicSettingsDto} updateUserBasicSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSettingsBasicControllerUpdateBasicSettings: async (updateUserBasicSettingsDto: UpdateUserBasicSettingsDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateUserBasicSettingsDto' is not null or undefined
            assertParamExists('userSettingsBasicControllerUpdateBasicSettings', 'updateUserBasicSettingsDto', updateUserBasicSettingsDto)
            const localVarPath = `/v1/user/settings/basic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserBasicSettingsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TwoFactorVerificationRequest} twoFactorVerificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSettingsEmailControllerApproveUserEmailChange: async (twoFactorVerificationRequest: TwoFactorVerificationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'twoFactorVerificationRequest' is not null or undefined
            assertParamExists('userSettingsEmailControllerApproveUserEmailChange', 'twoFactorVerificationRequest', twoFactorVerificationRequest)
            const localVarPath = `/v1/user/settings/email/approve`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(twoFactorVerificationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateUserEmailDto} updateUserEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSettingsEmailControllerRequestUserEmailChange: async (updateUserEmailDto: UpdateUserEmailDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateUserEmailDto' is not null or undefined
            assertParamExists('userSettingsEmailControllerRequestUserEmailChange', 'updateUserEmailDto', updateUserEmailDto)
            const localVarPath = `/v1/user/settings/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserEmailDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TwoFactorVerificationRequest} twoFactorVerificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSettingsTwoFactorControllerApproveUserTwoFactorEnabledChange: async (twoFactorVerificationRequest: TwoFactorVerificationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'twoFactorVerificationRequest' is not null or undefined
            assertParamExists('userSettingsTwoFactorControllerApproveUserTwoFactorEnabledChange', 'twoFactorVerificationRequest', twoFactorVerificationRequest)
            const localVarPath = `/v1/user/settings/two-factor/approve`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(twoFactorVerificationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateUserTwoFactorEnabledDto} updateUserTwoFactorEnabledDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSettingsTwoFactorControllerRequestUserTwoFactorEnabledChange: async (updateUserTwoFactorEnabledDto: UpdateUserTwoFactorEnabledDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateUserTwoFactorEnabledDto' is not null or undefined
            assertParamExists('userSettingsTwoFactorControllerRequestUserTwoFactorEnabledChange', 'updateUserTwoFactorEnabledDto', updateUserTwoFactorEnabledDto)
            const localVarPath = `/v1/user/settings/two-factor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserTwoFactorEnabledDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddWalletDto} addWalletDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletControllerAddWallet: async (addWalletDto: AddWalletDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addWalletDto' is not null or undefined
            assertParamExists('walletControllerAddWallet', 'addWalletDto', addWalletDto)
            const localVarPath = `/v1/wallet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addWalletDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompleteWalletVerificationDto} completeWalletVerificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletControllerCompleteWalletVerification: async (completeWalletVerificationDto: CompleteWalletVerificationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'completeWalletVerificationDto' is not null or undefined
            assertParamExists('walletControllerCompleteWalletVerification', 'completeWalletVerificationDto', completeWalletVerificationDto)
            const localVarPath = `/v1/wallet/complete-verification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(completeWalletVerificationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletControllerGetWalletTypes: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/wallet/type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletControllerGetWallets: async (currency?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/wallet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletControllerRemoveWallet: async (walletId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            assertParamExists('walletControllerRemoveWallet', 'walletId', walletId)
            const localVarPath = `/v1/wallet/{walletId}`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StartWalletVerificationDto} startWalletVerificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletControllerStartWalletVerification: async (startWalletVerificationDto: StartWalletVerificationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startWalletVerificationDto' is not null or undefined
            assertParamExists('walletControllerStartWalletVerification', 'startWalletVerificationDto', startWalletVerificationDto)
            const localVarPath = `/v1/wallet/start-verification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startWalletVerificationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} withdrawalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        withdrawalControllerCancelWithdraw: async (withdrawalId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'withdrawalId' is not null or undefined
            assertParamExists('withdrawalControllerCancelWithdraw', 'withdrawalId', withdrawalId)
            const localVarPath = `/v1/withdrawal/{withdrawalId}`
                .replace(`{${"withdrawalId"}}`, encodeURIComponent(String(withdrawalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        withdrawalControllerGetWithdraws: async (currency?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/withdrawal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WithdrawRequestDto} withdrawRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        withdrawalControllerRequestWithdraw: async (withdrawRequestDto: WithdrawRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'withdrawRequestDto' is not null or undefined
            assertParamExists('withdrawalControllerRequestWithdraw', 'withdrawRequestDto', withdrawRequestDto)
            const localVarPath = `/v1/withdrawal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(withdrawRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} withdrawalId 
         * @param {TwoFactorVerificationRequest} twoFactorVerificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        withdrawalControllerVerifyWithdrawal: async (withdrawalId: string, twoFactorVerificationRequest: TwoFactorVerificationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'withdrawalId' is not null or undefined
            assertParamExists('withdrawalControllerVerifyWithdrawal', 'withdrawalId', withdrawalId)
            // verify required parameter 'twoFactorVerificationRequest' is not null or undefined
            assertParamExists('withdrawalControllerVerifyWithdrawal', 'twoFactorVerificationRequest', twoFactorVerificationRequest)
            const localVarPath = `/v1/withdrawal/{withdrawalId}/verify`
                .replace(`{${"withdrawalId"}}`, encodeURIComponent(String(withdrawalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(twoFactorVerificationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} withdrawalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        withdrawalControllerVerifyWithdrawalResend: async (withdrawalId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'withdrawalId' is not null or undefined
            assertParamExists('withdrawalControllerVerifyWithdrawalResend', 'withdrawalId', withdrawalId)
            const localVarPath = `/v1/withdrawal/{withdrawalId}/verify/resend`
                .replace(`{${"withdrawalId"}}`, encodeURIComponent(String(withdrawalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actionControllerClaimPost(postId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actionControllerClaimPost(postId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.actionControllerClaimPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actionControllerConfirmPayout(campaignId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actionControllerConfirmPayout(campaignId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.actionControllerConfirmPayout']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreatePostRequestDto} createPostRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actionControllerCreatePost(createPostRequestDto: CreatePostRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatePostResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actionControllerCreatePost(createPostRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.actionControllerCreatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actionControllerHidePost(postId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actionControllerHidePost(postId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.actionControllerHidePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateUserRequestDto} createUserRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerCreateUser(createUserRequestDto: CreateUserRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateUserResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerCreateUser(createUserRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.authControllerCreateUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerGetProfile(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthProfileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerGetProfile(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.authControllerGetProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RefreshTokenRequestDto} refreshTokenRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerRefreshToken(refreshTokenRequestDto: RefreshTokenRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefreshTokenResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerRefreshToken(refreshTokenRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.authControllerRefreshToken']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [afterLoginRedirectUrl] 
         * @param {string} [onCancelLoginRedirectUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerTwitterAdvertiserLogin(afterLoginRedirectUrl?: string, onCancelLoginRedirectUrl?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalLoginUrlDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerTwitterAdvertiserLogin(afterLoginRedirectUrl, onCancelLoginRedirectUrl, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.authControllerTwitterAdvertiserLogin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} state 
         * @param {string} [code] 
         * @param {string} [error] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerTwitterCallback(state: string, code?: string, error?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerTwitterCallback(state, code, error, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.authControllerTwitterCallback']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [afterLoginRedirectUrl] 
         * @param {string} [onCancelLoginRedirectUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerTwitterReadLogin(afterLoginRedirectUrl?: string, onCancelLoginRedirectUrl?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalLoginUrlDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerTwitterReadLogin(afterLoginRedirectUrl, onCancelLoginRedirectUrl, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.authControllerTwitterReadLogin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [afterLoginRedirectUrl] 
         * @param {string} [onCancelLoginRedirectUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerTwitterWriteLogin(afterLoginRedirectUrl?: string, onCancelLoginRedirectUrl?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalLoginUrlDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerTwitterWriteLogin(afterLoginRedirectUrl, onCancelLoginRedirectUrl, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.authControllerTwitterWriteLogin']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async balanceControllerGetBalance(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BalanceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.balanceControllerGetBalance(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.balanceControllerGetBalance']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignPageControllerGetCampaignDetail(campaignId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignPageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignPageControllerGetCampaignDetail(campaignId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.campaignPageControllerGetCampaignDetail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignPageControllerGetCampaigns(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignsPageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignPageControllerGetCampaigns(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.campaignPageControllerGetCampaigns']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignPageControllerGetPastCampaigns(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignsPageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignPageControllerGetPastCampaigns(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.campaignPageControllerGetPastCampaigns']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignWalletsControllerGetCampaignWallets(campaignId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WalletDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignWalletsControllerGetCampaignWallets(campaignId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.campaignWalletsControllerGetCampaignWallets']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {WalletEventDto} walletEventDto 
         * @param {string} [xApiSecret] 
         * @param {string} [xApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conversionControllerIngestEvent(walletEventDto: WalletEventDto, xApiSecret?: string, xApiKey?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.conversionControllerIngestEvent(walletEventDto, xApiSecret, xApiKey, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.conversionControllerIngestEvent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dashboardPageControllerGetDashboardPage(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardPageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dashboardPageControllerGetDashboardPage(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.dashboardPageControllerGetDashboardPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} advertiserName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async homePageControllerGetCampaignsByAdvertiserName(advertiserName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HomePageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.homePageControllerGetCampaignsByAdvertiserName(advertiserName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.homePageControllerGetCampaignsByAdvertiserName']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} publicToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async invitationPageControllerGetInvitationPage(publicToken: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvitationPageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.invitationPageControllerGetInvitationPage(publicToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.invitationPageControllerGetInvitationPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {JoinCampaignRequestDto} joinCampaignRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async joinCampaignControllerJoinCampaign(campaignId: string, joinCampaignRequestDto: JoinCampaignRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JoinCampaignResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.joinCampaignControllerJoinCampaign(campaignId, joinCampaignRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.joinCampaignControllerJoinCampaign']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationControllerGetNotifications(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NotificationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationControllerGetNotifications(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.notificationControllerGetNotifications']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationControllerMarkAllNotificationsRead(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationControllerMarkAllNotificationsRead(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.notificationControllerMarkAllNotificationsRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationControllerMarkNotificationRead(notificationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationControllerMarkNotificationRead(notificationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.notificationControllerMarkNotificationRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async payoutControllerGetPendingPayouts(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PayoutDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.payoutControllerGetPendingPayouts(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.payoutControllerGetPendingPayouts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pingControllerPing(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pingControllerPing(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.pingControllerPing']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDetailControllerGetPostDetail(postId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostDetailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDetailControllerGetPostDetail(postId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.postDetailControllerGetPostDetail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} postId 
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDetailControllerGetPostDetailWithinCampaign(postId: string, campaignId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostDetailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDetailControllerGetPostDetailWithinCampaign(postId, campaignId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.postDetailControllerGetPostDetailWithinCampaign']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profilePageControllerGetProfilePage(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfilePageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profilePageControllerGetProfilePage(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.profilePageControllerGetProfilePage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {StartSpoofingDto} startSpoofingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async spoofingControllerStartSpoofing(startSpoofingDto: StartSpoofingDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.spoofingControllerStartSpoofing(startSpoofingDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.spoofingControllerStartSpoofing']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async spoofingControllerStopSpoofing(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.spoofingControllerStopSpoofing(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.spoofingControllerStopSpoofing']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSettingsBasicControllerGetUserSettings(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSettingsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userSettingsBasicControllerGetUserSettings(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.userSettingsBasicControllerGetUserSettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateUserBasicSettingsDto} updateUserBasicSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSettingsBasicControllerUpdateBasicSettings(updateUserBasicSettingsDto: UpdateUserBasicSettingsDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userSettingsBasicControllerUpdateBasicSettings(updateUserBasicSettingsDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.userSettingsBasicControllerUpdateBasicSettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {TwoFactorVerificationRequest} twoFactorVerificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSettingsEmailControllerApproveUserEmailChange(twoFactorVerificationRequest: TwoFactorVerificationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userSettingsEmailControllerApproveUserEmailChange(twoFactorVerificationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.userSettingsEmailControllerApproveUserEmailChange']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateUserEmailDto} updateUserEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSettingsEmailControllerRequestUserEmailChange(updateUserEmailDto: UpdateUserEmailDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userSettingsEmailControllerRequestUserEmailChange(updateUserEmailDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.userSettingsEmailControllerRequestUserEmailChange']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {TwoFactorVerificationRequest} twoFactorVerificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSettingsTwoFactorControllerApproveUserTwoFactorEnabledChange(twoFactorVerificationRequest: TwoFactorVerificationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userSettingsTwoFactorControllerApproveUserTwoFactorEnabledChange(twoFactorVerificationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.userSettingsTwoFactorControllerApproveUserTwoFactorEnabledChange']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateUserTwoFactorEnabledDto} updateUserTwoFactorEnabledDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSettingsTwoFactorControllerRequestUserTwoFactorEnabledChange(updateUserTwoFactorEnabledDto: UpdateUserTwoFactorEnabledDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userSettingsTwoFactorControllerRequestUserTwoFactorEnabledChange(updateUserTwoFactorEnabledDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.userSettingsTwoFactorControllerRequestUserTwoFactorEnabledChange']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AddWalletDto} addWalletDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async walletControllerAddWallet(addWalletDto: AddWalletDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WalletCreatedDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.walletControllerAddWallet(addWalletDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.walletControllerAddWallet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CompleteWalletVerificationDto} completeWalletVerificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async walletControllerCompleteWalletVerification(completeWalletVerificationDto: CompleteWalletVerificationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.walletControllerCompleteWalletVerification(completeWalletVerificationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.walletControllerCompleteWalletVerification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async walletControllerGetWalletTypes(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WalletTypeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.walletControllerGetWalletTypes(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.walletControllerGetWalletTypes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async walletControllerGetWallets(currency?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WalletDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.walletControllerGetWallets(currency, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.walletControllerGetWallets']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async walletControllerRemoveWallet(walletId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.walletControllerRemoveWallet(walletId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.walletControllerRemoveWallet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {StartWalletVerificationDto} startWalletVerificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async walletControllerStartWalletVerification(startWalletVerificationDto: StartWalletVerificationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartWalletVerificationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.walletControllerStartWalletVerification(startWalletVerificationDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.walletControllerStartWalletVerification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} withdrawalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async withdrawalControllerCancelWithdraw(withdrawalId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.withdrawalControllerCancelWithdraw(withdrawalId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.withdrawalControllerCancelWithdraw']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async withdrawalControllerGetWithdraws(currency?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WithdrawalDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.withdrawalControllerGetWithdraws(currency, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.withdrawalControllerGetWithdraws']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {WithdrawRequestDto} withdrawRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async withdrawalControllerRequestWithdraw(withdrawRequestDto: WithdrawRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WithdrawalDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.withdrawalControllerRequestWithdraw(withdrawRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.withdrawalControllerRequestWithdraw']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} withdrawalId 
         * @param {TwoFactorVerificationRequest} twoFactorVerificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async withdrawalControllerVerifyWithdrawal(withdrawalId: string, twoFactorVerificationRequest: TwoFactorVerificationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.withdrawalControllerVerifyWithdrawal(withdrawalId, twoFactorVerificationRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.withdrawalControllerVerifyWithdrawal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} withdrawalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async withdrawalControllerVerifyWithdrawalResend(withdrawalId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.withdrawalControllerVerifyWithdrawalResend(withdrawalId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.withdrawalControllerVerifyWithdrawalResend']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionControllerClaimPost(postId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.actionControllerClaimPost(postId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionControllerConfirmPayout(campaignId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.actionControllerConfirmPayout(campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreatePostRequestDto} createPostRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionControllerCreatePost(createPostRequestDto: CreatePostRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<CreatePostResponseDto> {
            return localVarFp.actionControllerCreatePost(createPostRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionControllerHidePost(postId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.actionControllerHidePost(postId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateUserRequestDto} createUserRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerCreateUser(createUserRequestDto: CreateUserRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<CreateUserResponseDto> {
            return localVarFp.authControllerCreateUser(createUserRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerGetProfile(options?: RawAxiosRequestConfig): AxiosPromise<AuthProfileDto> {
            return localVarFp.authControllerGetProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RefreshTokenRequestDto} refreshTokenRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRefreshToken(refreshTokenRequestDto: RefreshTokenRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<RefreshTokenResponseDto> {
            return localVarFp.authControllerRefreshToken(refreshTokenRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [afterLoginRedirectUrl] 
         * @param {string} [onCancelLoginRedirectUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerTwitterAdvertiserLogin(afterLoginRedirectUrl?: string, onCancelLoginRedirectUrl?: string, options?: RawAxiosRequestConfig): AxiosPromise<ExternalLoginUrlDto> {
            return localVarFp.authControllerTwitterAdvertiserLogin(afterLoginRedirectUrl, onCancelLoginRedirectUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} state 
         * @param {string} [code] 
         * @param {string} [error] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerTwitterCallback(state: string, code?: string, error?: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.authControllerTwitterCallback(state, code, error, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [afterLoginRedirectUrl] 
         * @param {string} [onCancelLoginRedirectUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerTwitterReadLogin(afterLoginRedirectUrl?: string, onCancelLoginRedirectUrl?: string, options?: RawAxiosRequestConfig): AxiosPromise<ExternalLoginUrlDto> {
            return localVarFp.authControllerTwitterReadLogin(afterLoginRedirectUrl, onCancelLoginRedirectUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [afterLoginRedirectUrl] 
         * @param {string} [onCancelLoginRedirectUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerTwitterWriteLogin(afterLoginRedirectUrl?: string, onCancelLoginRedirectUrl?: string, options?: RawAxiosRequestConfig): AxiosPromise<ExternalLoginUrlDto> {
            return localVarFp.authControllerTwitterWriteLogin(afterLoginRedirectUrl, onCancelLoginRedirectUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        balanceControllerGetBalance(options?: RawAxiosRequestConfig): AxiosPromise<BalanceDto> {
            return localVarFp.balanceControllerGetBalance(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignPageControllerGetCampaignDetail(campaignId: string, options?: RawAxiosRequestConfig): AxiosPromise<CampaignPageDto> {
            return localVarFp.campaignPageControllerGetCampaignDetail(campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignPageControllerGetCampaigns(options?: RawAxiosRequestConfig): AxiosPromise<CampaignsPageDto> {
            return localVarFp.campaignPageControllerGetCampaigns(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignPageControllerGetPastCampaigns(options?: RawAxiosRequestConfig): AxiosPromise<CampaignsPageDto> {
            return localVarFp.campaignPageControllerGetPastCampaigns(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignWalletsControllerGetCampaignWallets(campaignId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<WalletDto>> {
            return localVarFp.campaignWalletsControllerGetCampaignWallets(campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WalletEventDto} walletEventDto 
         * @param {string} [xApiSecret] 
         * @param {string} [xApiKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conversionControllerIngestEvent(walletEventDto: WalletEventDto, xApiSecret?: string, xApiKey?: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.conversionControllerIngestEvent(walletEventDto, xApiSecret, xApiKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dashboardPageControllerGetDashboardPage(options?: RawAxiosRequestConfig): AxiosPromise<DashboardPageDto> {
            return localVarFp.dashboardPageControllerGetDashboardPage(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} advertiserName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        homePageControllerGetCampaignsByAdvertiserName(advertiserName: string, options?: RawAxiosRequestConfig): AxiosPromise<HomePageDto> {
            return localVarFp.homePageControllerGetCampaignsByAdvertiserName(advertiserName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} publicToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        invitationPageControllerGetInvitationPage(publicToken: string, options?: RawAxiosRequestConfig): AxiosPromise<InvitationPageDto> {
            return localVarFp.invitationPageControllerGetInvitationPage(publicToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} campaignId 
         * @param {JoinCampaignRequestDto} joinCampaignRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        joinCampaignControllerJoinCampaign(campaignId: string, joinCampaignRequestDto: JoinCampaignRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<JoinCampaignResponseDto> {
            return localVarFp.joinCampaignControllerJoinCampaign(campaignId, joinCampaignRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerGetNotifications(options?: RawAxiosRequestConfig): AxiosPromise<Array<NotificationDto>> {
            return localVarFp.notificationControllerGetNotifications(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerMarkAllNotificationsRead(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.notificationControllerMarkAllNotificationsRead(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} notificationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerMarkNotificationRead(notificationId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.notificationControllerMarkNotificationRead(notificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payoutControllerGetPendingPayouts(options?: RawAxiosRequestConfig): AxiosPromise<Array<PayoutDto>> {
            return localVarFp.payoutControllerGetPendingPayouts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingControllerPing(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.pingControllerPing(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDetailControllerGetPostDetail(postId: string, options?: RawAxiosRequestConfig): AxiosPromise<PostDetailDto> {
            return localVarFp.postDetailControllerGetPostDetail(postId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} postId 
         * @param {string} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDetailControllerGetPostDetailWithinCampaign(postId: string, campaignId: string, options?: RawAxiosRequestConfig): AxiosPromise<PostDetailDto> {
            return localVarFp.postDetailControllerGetPostDetailWithinCampaign(postId, campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profilePageControllerGetProfilePage(options?: RawAxiosRequestConfig): AxiosPromise<ProfilePageDto> {
            return localVarFp.profilePageControllerGetProfilePage(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StartSpoofingDto} startSpoofingDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spoofingControllerStartSpoofing(startSpoofingDto: StartSpoofingDto, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.spoofingControllerStartSpoofing(startSpoofingDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spoofingControllerStopSpoofing(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.spoofingControllerStopSpoofing(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSettingsBasicControllerGetUserSettings(options?: RawAxiosRequestConfig): AxiosPromise<UserSettingsDto> {
            return localVarFp.userSettingsBasicControllerGetUserSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateUserBasicSettingsDto} updateUserBasicSettingsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSettingsBasicControllerUpdateBasicSettings(updateUserBasicSettingsDto: UpdateUserBasicSettingsDto, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.userSettingsBasicControllerUpdateBasicSettings(updateUserBasicSettingsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TwoFactorVerificationRequest} twoFactorVerificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSettingsEmailControllerApproveUserEmailChange(twoFactorVerificationRequest: TwoFactorVerificationRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.userSettingsEmailControllerApproveUserEmailChange(twoFactorVerificationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateUserEmailDto} updateUserEmailDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSettingsEmailControllerRequestUserEmailChange(updateUserEmailDto: UpdateUserEmailDto, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.userSettingsEmailControllerRequestUserEmailChange(updateUserEmailDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TwoFactorVerificationRequest} twoFactorVerificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSettingsTwoFactorControllerApproveUserTwoFactorEnabledChange(twoFactorVerificationRequest: TwoFactorVerificationRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.userSettingsTwoFactorControllerApproveUserTwoFactorEnabledChange(twoFactorVerificationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateUserTwoFactorEnabledDto} updateUserTwoFactorEnabledDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSettingsTwoFactorControllerRequestUserTwoFactorEnabledChange(updateUserTwoFactorEnabledDto: UpdateUserTwoFactorEnabledDto, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.userSettingsTwoFactorControllerRequestUserTwoFactorEnabledChange(updateUserTwoFactorEnabledDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddWalletDto} addWalletDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletControllerAddWallet(addWalletDto: AddWalletDto, options?: RawAxiosRequestConfig): AxiosPromise<WalletCreatedDto> {
            return localVarFp.walletControllerAddWallet(addWalletDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompleteWalletVerificationDto} completeWalletVerificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletControllerCompleteWalletVerification(completeWalletVerificationDto: CompleteWalletVerificationDto, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.walletControllerCompleteWalletVerification(completeWalletVerificationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletControllerGetWalletTypes(options?: RawAxiosRequestConfig): AxiosPromise<Array<WalletTypeDto>> {
            return localVarFp.walletControllerGetWalletTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletControllerGetWallets(currency?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<WalletDto>> {
            return localVarFp.walletControllerGetWallets(currency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} walletId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletControllerRemoveWallet(walletId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.walletControllerRemoveWallet(walletId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StartWalletVerificationDto} startWalletVerificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        walletControllerStartWalletVerification(startWalletVerificationDto: StartWalletVerificationDto, options?: RawAxiosRequestConfig): AxiosPromise<StartWalletVerificationResponseDto> {
            return localVarFp.walletControllerStartWalletVerification(startWalletVerificationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} withdrawalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        withdrawalControllerCancelWithdraw(withdrawalId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.withdrawalControllerCancelWithdraw(withdrawalId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [currency] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        withdrawalControllerGetWithdraws(currency?: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<WithdrawalDto>> {
            return localVarFp.withdrawalControllerGetWithdraws(currency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {WithdrawRequestDto} withdrawRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        withdrawalControllerRequestWithdraw(withdrawRequestDto: WithdrawRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<WithdrawalDto> {
            return localVarFp.withdrawalControllerRequestWithdraw(withdrawRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} withdrawalId 
         * @param {TwoFactorVerificationRequest} twoFactorVerificationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        withdrawalControllerVerifyWithdrawal(withdrawalId: string, twoFactorVerificationRequest: TwoFactorVerificationRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.withdrawalControllerVerifyWithdrawal(withdrawalId, twoFactorVerificationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} withdrawalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        withdrawalControllerVerifyWithdrawalResend(withdrawalId: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.withdrawalControllerVerifyWithdrawalResend(withdrawalId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {string} postId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public actionControllerClaimPost(postId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).actionControllerClaimPost(postId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} campaignId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public actionControllerConfirmPayout(campaignId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).actionControllerConfirmPayout(campaignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreatePostRequestDto} createPostRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public actionControllerCreatePost(createPostRequestDto: CreatePostRequestDto, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).actionControllerCreatePost(createPostRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} postId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public actionControllerHidePost(postId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).actionControllerHidePost(postId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateUserRequestDto} createUserRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authControllerCreateUser(createUserRequestDto: CreateUserRequestDto, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authControllerCreateUser(createUserRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authControllerGetProfile(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authControllerGetProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RefreshTokenRequestDto} refreshTokenRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authControllerRefreshToken(refreshTokenRequestDto: RefreshTokenRequestDto, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authControllerRefreshToken(refreshTokenRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [afterLoginRedirectUrl] 
     * @param {string} [onCancelLoginRedirectUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authControllerTwitterAdvertiserLogin(afterLoginRedirectUrl?: string, onCancelLoginRedirectUrl?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authControllerTwitterAdvertiserLogin(afterLoginRedirectUrl, onCancelLoginRedirectUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} state 
     * @param {string} [code] 
     * @param {string} [error] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authControllerTwitterCallback(state: string, code?: string, error?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authControllerTwitterCallback(state, code, error, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [afterLoginRedirectUrl] 
     * @param {string} [onCancelLoginRedirectUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authControllerTwitterReadLogin(afterLoginRedirectUrl?: string, onCancelLoginRedirectUrl?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authControllerTwitterReadLogin(afterLoginRedirectUrl, onCancelLoginRedirectUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [afterLoginRedirectUrl] 
     * @param {string} [onCancelLoginRedirectUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public authControllerTwitterWriteLogin(afterLoginRedirectUrl?: string, onCancelLoginRedirectUrl?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).authControllerTwitterWriteLogin(afterLoginRedirectUrl, onCancelLoginRedirectUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public balanceControllerGetBalance(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).balanceControllerGetBalance(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} campaignId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public campaignPageControllerGetCampaignDetail(campaignId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).campaignPageControllerGetCampaignDetail(campaignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public campaignPageControllerGetCampaigns(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).campaignPageControllerGetCampaigns(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public campaignPageControllerGetPastCampaigns(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).campaignPageControllerGetPastCampaigns(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} campaignId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public campaignWalletsControllerGetCampaignWallets(campaignId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).campaignWalletsControllerGetCampaignWallets(campaignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WalletEventDto} walletEventDto 
     * @param {string} [xApiSecret] 
     * @param {string} [xApiKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public conversionControllerIngestEvent(walletEventDto: WalletEventDto, xApiSecret?: string, xApiKey?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).conversionControllerIngestEvent(walletEventDto, xApiSecret, xApiKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public dashboardPageControllerGetDashboardPage(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).dashboardPageControllerGetDashboardPage(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} advertiserName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public homePageControllerGetCampaignsByAdvertiserName(advertiserName: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).homePageControllerGetCampaignsByAdvertiserName(advertiserName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} publicToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public invitationPageControllerGetInvitationPage(publicToken: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).invitationPageControllerGetInvitationPage(publicToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} campaignId 
     * @param {JoinCampaignRequestDto} joinCampaignRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public joinCampaignControllerJoinCampaign(campaignId: string, joinCampaignRequestDto: JoinCampaignRequestDto, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).joinCampaignControllerJoinCampaign(campaignId, joinCampaignRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public notificationControllerGetNotifications(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).notificationControllerGetNotifications(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public notificationControllerMarkAllNotificationsRead(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).notificationControllerMarkAllNotificationsRead(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} notificationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public notificationControllerMarkNotificationRead(notificationId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).notificationControllerMarkNotificationRead(notificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public payoutControllerGetPendingPayouts(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).payoutControllerGetPendingPayouts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public pingControllerPing(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).pingControllerPing(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} postId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postDetailControllerGetPostDetail(postId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postDetailControllerGetPostDetail(postId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} postId 
     * @param {string} campaignId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postDetailControllerGetPostDetailWithinCampaign(postId: string, campaignId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postDetailControllerGetPostDetailWithinCampaign(postId, campaignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public profilePageControllerGetProfilePage(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).profilePageControllerGetProfilePage(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StartSpoofingDto} startSpoofingDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public spoofingControllerStartSpoofing(startSpoofingDto: StartSpoofingDto, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).spoofingControllerStartSpoofing(startSpoofingDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public spoofingControllerStopSpoofing(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).spoofingControllerStopSpoofing(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userSettingsBasicControllerGetUserSettings(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userSettingsBasicControllerGetUserSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateUserBasicSettingsDto} updateUserBasicSettingsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userSettingsBasicControllerUpdateBasicSettings(updateUserBasicSettingsDto: UpdateUserBasicSettingsDto, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userSettingsBasicControllerUpdateBasicSettings(updateUserBasicSettingsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TwoFactorVerificationRequest} twoFactorVerificationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userSettingsEmailControllerApproveUserEmailChange(twoFactorVerificationRequest: TwoFactorVerificationRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userSettingsEmailControllerApproveUserEmailChange(twoFactorVerificationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateUserEmailDto} updateUserEmailDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userSettingsEmailControllerRequestUserEmailChange(updateUserEmailDto: UpdateUserEmailDto, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userSettingsEmailControllerRequestUserEmailChange(updateUserEmailDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TwoFactorVerificationRequest} twoFactorVerificationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userSettingsTwoFactorControllerApproveUserTwoFactorEnabledChange(twoFactorVerificationRequest: TwoFactorVerificationRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userSettingsTwoFactorControllerApproveUserTwoFactorEnabledChange(twoFactorVerificationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateUserTwoFactorEnabledDto} updateUserTwoFactorEnabledDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userSettingsTwoFactorControllerRequestUserTwoFactorEnabledChange(updateUserTwoFactorEnabledDto: UpdateUserTwoFactorEnabledDto, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).userSettingsTwoFactorControllerRequestUserTwoFactorEnabledChange(updateUserTwoFactorEnabledDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddWalletDto} addWalletDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public walletControllerAddWallet(addWalletDto: AddWalletDto, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).walletControllerAddWallet(addWalletDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompleteWalletVerificationDto} completeWalletVerificationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public walletControllerCompleteWalletVerification(completeWalletVerificationDto: CompleteWalletVerificationDto, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).walletControllerCompleteWalletVerification(completeWalletVerificationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public walletControllerGetWalletTypes(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).walletControllerGetWalletTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [currency] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public walletControllerGetWallets(currency?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).walletControllerGetWallets(currency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} walletId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public walletControllerRemoveWallet(walletId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).walletControllerRemoveWallet(walletId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StartWalletVerificationDto} startWalletVerificationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public walletControllerStartWalletVerification(startWalletVerificationDto: StartWalletVerificationDto, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).walletControllerStartWalletVerification(startWalletVerificationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} withdrawalId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public withdrawalControllerCancelWithdraw(withdrawalId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).withdrawalControllerCancelWithdraw(withdrawalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [currency] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public withdrawalControllerGetWithdraws(currency?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).withdrawalControllerGetWithdraws(currency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {WithdrawRequestDto} withdrawRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public withdrawalControllerRequestWithdraw(withdrawRequestDto: WithdrawRequestDto, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).withdrawalControllerRequestWithdraw(withdrawRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} withdrawalId 
     * @param {TwoFactorVerificationRequest} twoFactorVerificationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public withdrawalControllerVerifyWithdrawal(withdrawalId: string, twoFactorVerificationRequest: TwoFactorVerificationRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).withdrawalControllerVerifyWithdrawal(withdrawalId, twoFactorVerificationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} withdrawalId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public withdrawalControllerVerifyWithdrawalResend(withdrawalId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).withdrawalControllerVerifyWithdrawalResend(withdrawalId, options).then((request) => request(this.axios, this.basePath));
    }
}



